'use client'

import { useTranslations } from 'next-intl'
import { useState } from 'react'
import styled, { css } from 'styled-components'

import { Button, IconProfileOutline, generateColorLuminances, spacing } from '@fortum/elemental-ui'

import { isLoggedInServiceBreakEnabled } from '@/logged-in/components/ServiceBreak/serviceBreakUtils'
import { browserEnvs } from '@/shared/envs'
import { usePathname, useRouter } from '@/shared/navigation'
import { useClientSideConfs } from '@/shared/providers/appEnv'
import { routes, serviceBreakClient } from '@/shared/routes'
import { logError } from '@/shared/utils/error'
import { UiError } from '@/shared/utils/errorClasses'
import { stepEvent } from '@/shared/utils/sendGTMEvent'
import { signInHandler } from '@/shared/utils/signInHandler'

export type LoginButtonProps = {
  label?: string
  color?: string
  padding?: string
  height?: string
}

export const LoginButton = ({ label, color, padding, height }: LoginButtonProps) => {
  const t = useTranslations('mainNavigation')
  const router = useRouter()
  const pathname = usePathname()
  const clientSideConfs = useClientSideConfs()
  const [isLoading, setIsLoading] = useState(false)

  const onClickHandler = async () => {
    setIsLoading(true)
    let isServiceBreak = false
    try {
      isServiceBreak = await isLoggedInServiceBreakEnabled()
    } catch (error) {
      logError(new UiError('Failed to get LoggedInServiceBreak value', { cause: error }))
    } finally {
      if (isServiceBreak) {
        router.push({
          pathname: routes.SERVICE_BREAK,
          params: { client: serviceBreakClient.LOG_IN },
        })
      } else {
        await signInHandler(clientSideConfs.ciam.providerId)
        stepEvent('click_login_CTA', { site_brand: `fortum ${browserEnvs.NEXT_PUBLIC_COUNTRY}` })
      }
      setIsLoading(false)
    }
  }

  return (
    pathname !== routes.SERVICE_BREAK && (
      <StyledButton
        status="plain"
        leftIcon={IconProfileOutline}
        onClick={onClickHandler}
        color={color}
        aria-label={t('login')}
        loading={isLoading}
        fontSize={spacing.xxs}
        ph={padding}
        height={height}
        data-testid="loginButton"
      >
        {label}
      </StyledButton>
    )
  )
}

const StyledButton = styled(Button)`
  ${({ theme }) => css`
    &:hover {
      background: none;
      color: ${generateColorLuminances('hover', theme).bg};
    }

    &:active {
      background: none;
      color: ${generateColorLuminances('click', theme).bg};
    }
  `}
`
