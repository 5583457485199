import { serverEnvs } from './envs'

type NextCacheTag =
  | 'campaigns'
  | 'spotPrice'
  | 'spotPriceShort'
  | 'priceAreaByZipCode'
  | 'customerInfoBySsn'
  | 'contentful'
  | 'evCarModels'
  | 'consents'
  | 'enterpriseCustomerInfoByOrgId'

type NextCacheConfigItem = {
  tag: string
  /**
   * Time in seconds.
   */
  revalidate: number
}

export type NextCacheConfig = Record<NextCacheTag, NextCacheConfigItem>

const MINUTE = 60
const FIVE_MINUTES = 5 * MINUTE
const HALF_HOUR = 30 * MINUTE
const HOUR = 60 * MINUTE
const DAY = HOUR * 24

export const nextCacheConfig: NextCacheConfig = {
  campaigns: {
    tag: 'campaigns',
    revalidate: HALF_HOUR,
  },
  spotPrice: {
    tag: 'spotPrice',
    revalidate: FIVE_MINUTES,
  },
  spotPriceShort: {
    tag: 'spotPrice',
    revalidate: MINUTE,
  },
  priceAreaByZipCode: {
    tag: 'priceAreaByZipCode',
    revalidate: DAY,
  },
  customerInfoBySsn: {
    tag: 'customerInfoBySsn',
    revalidate: HOUR,
  },
  contentful: {
    revalidate: serverEnvs.APP_ENV === 'prd' ? HOUR : FIVE_MINUTES, //1 hour in prd, 5 minute otherwise
    tag: 'contentful',
  },
  evCarModels: {
    revalidate: HOUR,
    tag: 'evCarModels',
  },
  consents: {
    revalidate: serverEnvs.APP_ENV === 'prd' ? HOUR : FIVE_MINUTES,
    tag: 'consents',
  },
  enterpriseCustomerInfoByOrgId: {
    tag: 'enterpriseCustomerInfoByOrgId',
    revalidate: HOUR,
  },
}
