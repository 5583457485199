'use client'

import { useMainLayoutContent } from './mainLayoutContent'
import type { FeatureFlagKey } from '../featureFlags'
import { logError } from '../utils/error'
import { UiError } from '../utils/errorClasses'

export const useFeatureFlag = (key: FeatureFlagKey): boolean => {
  const { featureFlags } = useMainLayoutContent()
  if (!featureFlags && !process.env.STORYBOOK) {
    throw logError(new UiError('useFeatureFlag must be used within a FeatureFlagProvider'))
  }

  //NOTE: Return true for feature flags if run in storybook
  return featureFlags?.[key] ?? (!!process.env.STORYBOOK || false)
}
