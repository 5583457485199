'use client'

import { useSearchParams } from 'next/navigation'
import { useEffect } from 'react'

import { logError } from '@/shared/utils/error'
import { UiError } from '@/shared/utils/errorClasses'

const PARAM = 'error-test-layout'

/**
 * Component for testing the global error page, which is shown only when an error occurs in the layout,
 * and when the app is running in production mode.
 */
export const LayoutErrorTest = () => {
  const searchParams = useSearchParams()
  const hasParam = searchParams.has(PARAM)

  useEffect(() => {
    if (hasParam) {
      throw logError(new UiError('Test error in layout'))
    }
  }, [hasParam])

  return null
}
