import type React from 'react'
import { useMemo } from 'react'

import type { ServiceAnnouncementWithPageUrl } from '@/logged-in/components/ServiceAnnouncementNotification/serviceAnnouncementUtils'

import type { AnnouncementStyleType } from '../ServiceAnnouncementBanner/ServiceAnnouncementBanner'
import { ServiceAnnouncementBanner } from '../ServiceAnnouncementBanner/ServiceAnnouncementBanner'

const typePriority = {
  Warning: 1,
  Error: 2,
  General: 3,
  Info: 4,
} satisfies Record<AnnouncementStyleType, number>

export type ServiceAnnouncementListProps = {
  announcements: ServiceAnnouncementWithPageUrl[]
  onClose: (id: ServiceAnnouncementWithPageUrl['id']) => void
}

export const ServiceAnnouncementList: React.FC<ServiceAnnouncementListProps> = ({
  announcements,
  onClose,
}) => {
  const sortedAnnouncements = useMemo(() => {
    return announcements.toSorted(
      (A, B) =>
        typePriority[A.type as AnnouncementStyleType] -
        typePriority[B.type as AnnouncementStyleType],
    )
  }, [announcements])

  return sortedAnnouncements.map((announcement) => (
    <ServiceAnnouncementBanner
      key={announcement.id}
      type={announcement.type as AnnouncementStyleType}
      title={announcement.title ?? ''}
      hideCloseButton={Boolean(announcement.hideCloseButton)}
      message={announcement.content}
      handleClose={() => onClose(announcement.id)}
    />
  ))
}
