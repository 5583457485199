'use client'

// eslint-disable-next-line no-restricted-imports
import { usePathname as nextUsePathname } from 'next/navigation'
import { useLayoutEffect, useState } from 'react'

import { getSessionUsername } from '@/shared/components/Navigation/getSessionUsername'
import type { LinkEntry, MainNavigationEntry } from '@/shared/contentful/types'
import { useRouter } from '@/shared/navigation'
import { isPathPrivate } from '@/shared/utils/isPathPrivate'

import { NavigationDesktop } from './NavigationDesktop'
import { NavigationMobile } from './NavigationMobile'
import { SearchModal } from './parts/SearchModal'
import { SkipToMainContent } from './parts/SkipToMainContent'

export type NavigationProps = {
  topNavigation?: LinkEntry[]
  mainNavigation: MainNavigationEntry
  searchUrl: string
}

const defaultTopNavigation: LinkEntry[] = []

/**
 * Component responsible for Application menu presentation
 */
export const Navigation = ({
  mainNavigation,
  topNavigation = defaultTopNavigation,
  searchUrl,
}: NavigationProps) => {
  // That is intended usage of next usePathname instead of next-intl which provide non localized pathname what means that for some dynamic pages non localized is the same
  // This behavior is something unwanted if we want to determinate when to close the menu depends on the path changed
  const localizedPathname = nextUsePathname()
  const router = useRouter()
  const isPrivate = isPathPrivate(localizedPathname)
  const [isSearchOpen, setSearchOpen] = useState(false)
  const [username, setUsername] = useState('')

  //NOTE: using useEffect didn't invoke the getSessionUsername serveraction at all in incognito mode and some other cases
  useLayoutEffect(() => {
    void getSessionUsername().then((name) => {
      setUsername(name ?? '')
    })
  }, [])

  return (
    <header style={{ display: 'contents' }}>
      <SkipToMainContent />
      <NavigationDesktop
        topNavigation={topNavigation}
        mainNavigation={mainNavigation}
        pathname={localizedPathname}
        username={username}
        isPrivate={isPrivate}
        router={router}
        onOpenSearch={() => setSearchOpen(true)}
      />
      <NavigationMobile
        topNavigation={topNavigation}
        mainNavigation={mainNavigation}
        pathname={localizedPathname}
        username={username}
        isPrivate={isPrivate}
        router={router}
        onOpenSearch={() => setSearchOpen(true)}
      />
      <SearchModal
        searchUrl={searchUrl}
        open={isSearchOpen}
        onCloseSearch={() => setSearchOpen(false)}
      />
    </header>
  )
}
