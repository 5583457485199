'use client'

import styled, { css } from 'styled-components'

import { Box, ContentText, fontSizes, generateColorLuminances, spacing } from '@fortum/elemental-ui'

import { StyledLink } from '@/shared/components/StyledLink'
import { useTheme } from '@/shared/hooks/useTheme'

type TopNavigationLinkProps = {
  /**
   * Text of the link
   */
  label: string
  /**
   * Url of the link
   */
  href: string
  /**
   * Url target, should the site open in different window or the same
   */
  target?: string
  /**
   * Flag to specify if button is active
   */
  isActive?: boolean
  /**
   * Data test id
   */
  dataTestId?: string
}

/**
 * Top navigation link component
 */
export const TopNavigationLink = ({
  label,
  href,
  target,
  isActive,
  dataTestId,
}: TopNavigationLinkProps) => {
  const { colors } = useTheme()

  return (
    <TopNavLink
      href={href}
      target={target}
      display="flex"
      aria-label={label}
      noUnderlineHover
      noUnderline
      tabIndex={0}
      alignItems="center"
      data-testid={dataTestId}
      $isActive={isActive}
      backgroundColor={isActive ? colors.brandPrimary : 'transparent'}
    >
      <Box
        padding={{ default: `${spacing.xxs} ${spacing.xs}`, xl: `0 ${spacing.xxs}` }}
        height={{ xl: spacing.m }}
        role="link"
        width={{ default: '100%', xl: 'auto' }}
        display="flex"
        alignItems="center"
      >
        <ContentText color={colors.textLightOnDark} fontSize={{ xl: fontSizes.xs.s }}>
          {label}
        </ContentText>
      </Box>
    </TopNavLink>
  )
}

const TopNavLink = styled(StyledLink)<{ $isActive?: boolean }>`
  ${({ theme, $isActive }) => css`
    ${$isActive &&
    css`
      &:hover,
      &:focus,
      &:active {
        background-color: ${generateColorLuminances('click', theme).bg};
      }
    `}
    &:hover,
    &:focus {
      background-color: ${generateColorLuminances('click', theme).bg};
    }

    &:active {
      background-color: ${generateColorLuminances('normal', theme, { color: '#071210' }).bg};
    }
  `}
`
