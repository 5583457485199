'use client'

// eslint-disable-next-line no-restricted-imports
import { usePathname as nextUsePathname } from 'next/navigation'

import { Box } from '@fortum/elemental-ui'

import { useTheme } from '@/shared/hooks/useTheme'
import { isPathPrivate } from '@/shared/utils/isPathPrivate'

type Props = {
  children: React.ReactNode
}

export const MainContent = ({ children }: Props) => {
  const { colors } = useTheme()
  const pathname = nextUsePathname()
  const isPrivate = isPathPrivate(pathname)

  return (
    <Box
      id="main-content"
      tag="main"
      backgroundColor={colors.backgroundPrimary}
      display={!isPrivate ? 'flex' : 'contents'}
      flexDirection="column"
      flexGrow={1}
    >
      {children}
    </Box>
  )
}
