'use client'

import type { ReactNode } from 'react'
import { createContext, useContext } from 'react'

import type { ClientSideSafeConfigs } from '@/shared/appConfigClient'
import { getClientSafeConfig } from '@/shared/appConfigClient'
import type { AppEnv } from '@/shared/envs'

import { logError } from '../utils/error'
import { UiError } from '../utils/errorClasses'

interface AppEnvContextProps {
  clientSideConfs: ClientSideSafeConfigs
}

const AppEnvContext = createContext<AppEnvContextProps | undefined>(undefined)

export const AppEnvProvider = ({ appEnv, children }: { appEnv: AppEnv; children: ReactNode }) => {
  return (
    <AppEnvContext.Provider value={{ clientSideConfs: getClientSafeConfig(appEnv) }}>
      {children}
    </AppEnvContext.Provider>
  )
}

export const useClientSideConfs = () => {
  const context = useContext(AppEnvContext)
  if (!context) {
    throw logError(new UiError('useClientSideConfs must be used within an AppEnvProvider'))
  }
  return context.clientSideConfs
}
