import type { AppConfig } from './appConfig'
import type { AppEnv, Country } from './envs'

/**
 * Please, make sure that this method is not exposed outside
 * of server environment. It includes auth tokens for GTM
 * and would open a security vulnerability for our data
 * reliability if exposed.
 */
type AppEnvOmit = Exclude<AppEnv, 'local' | 'prd'>
type GTMConfig = {
  [key in AppEnvOmit]: Pick<AppConfig['gtm'], 'auth' | 'preview'>
} & {
  id: AppConfig['gtm']['id']
}
export const getGTMConfig = (appEnv: AppEnvOmit, country: Country): AppConfig['gtm'] => {
  const gtm: Record<Exclude<Country, 'GB'>, GTMConfig> = {
    SE: {
      id: 'GTM-KC9QG59K',
      dev: {
        auth: 'QwT_fvxUQCq3eGUivjSnLw',
        preview: 'env-4',
      },
      stg: {
        auth: 'wEYL16Js5XZx3BAxSPXP6A',
        preview: 'env-3',
      },
    },
    FI: {
      id: 'GTM-MMSL9VTK',
      dev: {
        auth: '01lcHKupI5qvC8jOgfRxVw',
        preview: 'env-3',
      },
      stg: {
        auth: 'euB_Q9Sa3_dFy770LJNRLQ',
        preview: 'env-4',
      },
    },
    NO: {
      id: 'GTM-W5G35FN9',
      dev: {
        auth: 'MJvar-5YtQFXF4qBtWrXsA',
        preview: 'env-3',
      },
      stg: {
        auth: 'dZ6wUaeykRRzMTNBpBRoiw',
        preview: 'env-4',
      },
    },
  }

  switch (country) {
    case 'SE':
    case 'FI':
    case 'NO':
      return {
        id: gtm[country].id,
        ...gtm[country][appEnv],
      }
    default:
      return {
        id: gtm['SE'].id,
        ...gtm['SE'][appEnv],
      }
  }
}
