'use client'

import styled from 'styled-components'

import { Box, opacify, spacing } from '@fortum/elemental-ui'

import type { IconId } from '@/shared/components/Icon'
import { Icon } from '@/shared/components/Icon'
import { StateButton } from '@/shared/components/StateButton'
import { StyledLink } from '@/shared/components/StyledLink'
import { useTheme } from '@/shared/hooks/useTheme'

import { Text } from '../style'

export type FeatureItemProps = Omit<Link, 'text'> & {
  /**
   * Left icon
   */
  icon?: IconId
  /**
   * Primary text - single line
   */
  primaryText: string
  /**
   * Secondary text - up to three lines
   */
  secondaryText: string
}

export const FeatureItem = ({
  icon,
  primaryText,
  secondaryText,
  href,
  target,
}: FeatureItemProps) => {
  const theme = useTheme()

  return (
    <StyledLink
      href={href}
      target={target}
      aria-label={primaryText}
      display="flex"
      alignSelf="stretch"
      data-testid="feature-item-link"
      marginHorizontal={{ default: `-${spacing.xs}`, xl: 0 }}
    >
      <StyledButton width="100%" tabIndex={-1} p={0}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          pv={spacing.xxxs}
          ph={{ default: spacing.xs, xl: spacing.xxs }}
        >
          <Box display="flex">
            {icon && (
              <Icon icon={icon} color={theme.colors.brandPrimary} size={48} pr={spacing.xxs} />
            )}
            <Box display="flex" flexDirection="column">
              <Text
                color={theme.colors.brandPrimary}
                textAlign="left"
                title={primaryText}
                $maxLines={1}
              >
                {primaryText}
              </Text>
              <Text title={secondaryText} size="xs" textAlign="left" $maxLines={3}>
                {secondaryText}
              </Text>
            </Box>
          </Box>
        </Box>
      </StyledButton>
    </StyledLink>
  )
}

const StyledButton = styled(StateButton)`
  ${({ theme }) =>
    `
      &:hover{
        background-color: ${opacify(theme.colors.backgroundTertiary, 0.05)} ;
        border-color: transparent;
      }
      &:active, &:focus{
        background-color: ${opacify(theme.colors.backgroundTertiary, 0.1)} ;
        border-color: transparent;
      }
    `}
`
