'use client'

import styled from 'styled-components'

import { Box, opacify, px2rem, spacing } from '@fortum/elemental-ui'

import { ContentfulImage } from '@/shared/components/ContentfulImage'
import { StyledLink } from '@/shared/components/StyledLink'
import { useTheme } from '@/shared/hooks/useTheme'

import { Text } from '../style'

export type LargeFeatureItemProps = Omit<Link, 'text'> & {
  /**
   * Feature Image url
   */
  imgUrl: string
  /**
   * Image alt text
   */
  alt?: string
  /**
   * Primary text - single line
   */
  primaryText: string
  /**
   * Secondary text - up to three lines
   */
  secondaryText: string
}

/**
 * Footer feature item with image which move to given path after click
 */
export const LargeFeatureItem = ({
  imgUrl,
  primaryText,
  secondaryText,
  href,
  target,
  alt = 'feature item',
}: LargeFeatureItemProps) => {
  const theme = useTheme()

  return (
    <LinkWrapper
      noUnderlineHover
      href={href}
      target={target}
      ph={{ default: spacing.xs, xl: spacing.xxs }}
      pv={spacing.xxxs}
      marginHorizontal={{ default: `-${spacing.xs}`, xl: 0 }}
      aria-label={primaryText}
      display="block"
      width={{ default: px2rem(327), xl: px2rem(278) }}
      tabIndex={-1}
      data-testid="large-feature-item-link"
    >
      <Box tabIndex={0}>
        <Box position="relative" height={px2rem(192)} mb={spacing.xxs}>
          <ContentfulImage
            src={imgUrl}
            alt={alt}
            style={{ borderRadius: spacing.xxxxs }}
            maxWidth="500"
          />
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column">
            <Text color={theme.colors.brandPrimary} title={primaryText} $maxLines={1}>
              {primaryText}
            </Text>
            <Text title={secondaryText} size="xs" $maxLines={3}>
              {secondaryText}
            </Text>
          </Box>
        </Box>
      </Box>
    </LinkWrapper>
  )
}

const LinkWrapper = styled(StyledLink)`
  ${({ theme }) => `
  text-decoration: none !important;
  &:hover {
    background-color: ${opacify(theme.colors.backgroundTertiary, 0.05)};
  }
  &:active, &:focus{
    background-color: ${opacify(theme.colors.backgroundTertiary, 0.1)} ;
  }
`}
`
